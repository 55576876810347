<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap mt-1">
        <div class="mb-1">
          <h4>
            {{ $t("backoffice.legal.subtitle") }}
          </h4>
          <p>
            {{ $t("backoffice.legal.message") }}
            <b-link target="_blank" :href="policyURL">
              {{ $t("backoffice.legal.here") }}
            </b-link>
          </p>
        </div>

        <b-button
          variant="primary"
          class="align-self-start"
          @click="saveLegals()"
        >
          {{ $t("form.actions.save") }}
        </b-button>
      </div>
      <b-tabs nav-wrapper-class="legal-tabs" justified class="mx-3">
        <!-- GDPR -->
        <b-tab title-link-class="d-block">
          <template #title>
            <feather-icon icon="LockIcon" size="30" class="d-block mx-auto" />
            {{ $t("backoffice.legal.gdpr") }}
          </template>
          <div class="mt-2">
            <form-translation-table
              v-model="gdpr"
              class="my-50"
              :is-description="true"
              :is-rich="true"
              :value-placeholder="$t('layouts.widget-name')"
              label-attribute="name"
            />
          </div>
        </b-tab>
        <!-- Privacy Policy -->
        <b-tab title-link-class="d-block ">
          <template #title>
            <feather-icon icon="ShieldIcon" size="30" class="d-block mx-auto" />
            {{ $t("backoffice.legal.policy") }}
          </template>
          <div class="mt-2">
            <form-translation-table
              v-model="privacy"
              class="my-50"
              :is-description="true"
              :is-rich="true"
              :value-placeholder="$t('layouts.widget-name')"
              label-attribute="name"
            />
          </div>
        </b-tab>
        <!-- Terms -->
        <b-tab title-link-class="d-block">
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="30"
              class="d-block mx-auto"
            />
            {{ $t("backoffice.legal.terms") }}
          </template>
          <div class="mt-2">
            <form-translation-table
              v-model="terms"
              class="my-50"
              :is-description="true"
              :is-rich="true"
              :value-placeholder="$t('layouts.widget-name')"
              label-attribute="name"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </backoffice-container>
</template>

<script>
import { BCard, BButton, BTabs, BTab } from "bootstrap-vue";

import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import FormTranslationTable from "@core/components/form-translation-table/FormTranslationTable.vue";

export default {
  name: "Legal",
  components: {
    BButton,
    BackofficeContainer,
    BCard,
    FormTranslationTable,
    BTabs,
    BTab,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      gdpr: {},
      privacy: {},
      cookies: {},
      terms: {},
    };
  },
  computed: {
    policyURL() {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL?.replace(
        "{subdomain}",
        this.currentCollective.subdomain || "app"
      )?.replace("{slug}", this.currentCollective.slug)}/?locale=${
        this.locale
      }`;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    legal() {
      return this.$store.getters.legalBO?.community_customizations;
    },
    localeInUrl() {
      return this.$route.query.locale;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    await this.getLegalData();
    this.privacy =
      this.legal?.privacy_policy != null ? this.legal?.privacy_policy : {};
    this.terms =
      this.legal?.terms_conditions != null ? this.legal?.terms_conditions : {};
    this.cookies =
      this.legal?.cookies_policy != null ? this.legal?.cookies_policy : {};
    this.gdpr = this.legal?.gdpr != null ? this.legal?.gdpr : {};
    if (this.localeInUrl) {
      this.setLocale(this.localeInUrl);
    }
  },
  methods: {
    // async getLegalData() {
    //   await this.$store.dispatch('getCommunityCustomizations');
    // },
    async getLegalData() {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "backoffice/legal",
        forceAPICall: true,
        customName: "legalBO",
      });
      this.isLoading = false;
    },
    async saveLegals() {
      // if (this.form.nif && this.personForm.dni) {
      const settings = {
        itemType: "backoffice/legal",
        customName: "legalBO",
        requestConfig: {
          gdpr: this.gdpr,
          privacy: this.privacy,
          cookies: this.cookies,
          terms: this.terms,
        },
      };
      try {
        await this.$store.dispatch("editItem", {
          item: settings,
          forceUpdate: true,
        });
        this.notifySuccess(this.$t("backoffice.legal.messages.success"));
      } catch {
        this.notifyError(this.$t("backoffice.legal.messages.error"));
      }
      // } else {
      //   this.notifyError(this.$t('backoffice.faqs.messages.error'));
      // }
    },
    setLocale(language) {
      this.$store.dispatch("setLocale", language);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tabs .nav-link:after {
  height: 2px !important;
}
.svg-inline--fa {
  display: block;
  margin: 0 auto;
  height: 2.1em !important;
  width: auto !important;
}
.nav-tabs .nav-link svg {
  margin-right: auto;
}
.legal-tabs {
  display: flex !important;
  justify-content: space-around !important;
}
</style>
